import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const phoneNumber = '9962744380';

  return (
    <div className="relative">
      <a
        href={`https://wa.me/${phoneNumber}?text=Hello, How can we help you?`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-btn fixed bottom-10 right-9 z-50 p-3 bg-green-600 text-white md:bg-gray-50 md:text-green-600 rounded-full shadow-xl flex items-center space-x-1"
      >
        <FaWhatsapp className="text-white md:text-green-600" size={24} />
        <span className="text-black font-bold uppercase hidden md:block">Chat</span> 
      </a>
    </div>
  );
};

export default WhatsAppButton;
