import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import consultingImg from '../Assets/WhyChooseUs/consulting.png';
import plansImg from '../Assets/WhyChooseUs/plans.png';
import responsiveImg from '../Assets/WhyChooseUs/responsive.png';
import seoImg from '../Assets/WhyChooseUs/seo.png';
import supportImg from '../Assets/WhyChooseUs/support.png';
import paymentImg from '../Assets/WhyChooseUs/payment.gif';

const Alternative = () => {
  const desktopRef = useRef(null);
  const mobileRef = useRef(null);
  const inViewDesktop = useInView(desktopRef, { triggerOnce: true, threshold: 0.3 });
  const inViewMobile = useInView(mobileRef, { triggerOnce: true, threshold: 0.3 });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4, ease: 'easeIn' } },
  };

  return (
    <section className="block md:flex">
      {/* Desktop view */}
      <div className="hidden md:block min-h-screen mx-auto">

        <motion.div
          ref={desktopRef}
          initial="hidden"
          animate={inViewDesktop ? "visible" : "hidden"}
          variants={containerVariants}
          className="max-w-[1300px] mx-auto grid md:grid-cols-12 gap-6 py-12"
        >
          <motion.div variants={itemVariants} className="col-span-6 md:col-span-4 row-span-2 bg-[#fc8972] px-6 py-4 rounded-2xl shadow-2xl relative h-[370px] group">
            <div className="absolute inset-0 z-0">
              <img
                src={consultingImg}
                alt="Free Consultation"
                className="h-[300px] -mt-10 group-hover:scale-110 group-hover:-translate-y-10 transition-transform duration-300 ease-in-out"
              />
            </div>
            <div className="relative z-10 bg-[#fc8972]">
              <h1 className="text-xl text-[#862b1b] font-semibold mt-48">Free Consultation</h1>
              <p className="text-[#862b1b] text-[18px] mt-1">
                Speak with our experts to understand how a tailored eCommerce website can help your business thrive.
              </p>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-6 md:col-span-8 bg-[#848ef9] text-[#1a1f5b] rounded-2xl shadow-2xl flex">
            <div className="flex flex-col justify-center gap-2 p-4">
              <h1 className="text-xl font-semibold ">Personalized Project Plan</h1>
              <p className="text-[18px]">Receive a detailed project plan with timelines, costs, and milestones to ensure timely delivery.</p>
            </div>
            <div>
              <img src={plansImg} alt="Personalized Project Plan" className="h-full w-[500px] transform scale-x-[-1]" />
            </div>
          </motion.div>

          <HeaderBlock />

          <motion.div variants={itemVariants} className="col-span-6 md:col-span-4 row-span-2 p-6 bg-[#F0F5FB] text-[#2e598e] rounded-2xl shadow-2xl flex flex-col justify-between">
            <h1 className="text-lg  font-semibold ">Integration with Payment Gateways</h1>
            <img src={paymentImg} alt="Payment Integration" />
            <p className=" text-[18px]">Secure payment gateways and tools for confident shopping and efficient management.</p>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-6 md:col-span-4 bg-[#ffba08] text-[#681c3b] p-6 rounded-2xl shadow-2xl flex flex-col justify-between">
            <h1 className="text-lg font-semibold ">SEO & Performance Optimization</h1>
            <img src={seoImg} alt="SEO & Performance Optimization" className="h-auto" />
            <p className="text-[18px]">Best practices for SEO and speed to ensure high rankings and fast loading times.</p>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-6 md:col-span-4 bg-[#ABADDD] p-6 rounded-2xl shadow-2xl">
            <h1 className="text-lg font-semibold text-[#363853]">Fully Responsive & Scalable</h1>
            <img src={responsiveImg} alt="Fully Responsive" className="w-full scale-125" />
            <div>
              <p className="text-[18px] text-[#363853]">Optimized for any device to ensure a seamless shopping experience across all platforms.</p>
            </div>
          </motion.div>

          <div className="col-span-6 md:col-span-2">
          </div>

          <motion.div variants={itemVariants} className="col-span-6 md:col-span-8 bg-[#4BAB8A]  rounded-2xl shadow-2xl flex gap-4">
            <div className="flex flex-col gap-2 justify-center px-5">
              <h1 className="text-lg text-[#18382c] font-semibold ">Ongoing Support</h1>
              <p className="text-[#18382c] text-[18px]">We provide ongoing support and maintenance to keep your store running smoothly and securely.</p>
            </div>
            <img src={supportImg} alt="Ongoing Support" className="h-[250px] scale-125" />
          </motion.div>

          <div className="col-span-6 md:col-span-2">
          </div>

        </motion.div>
      </div>

      {/* mobile view */}
      <div className="block md:hidden ">
        <motion.div
          ref={mobileRef}
          initial="hidden"
          animate={inViewMobile ? "visible" : "hidden"}
          variants={containerVariants}
          className="grid grid-cols-1 gap-6 p-4"
        >
          <HeaderBlock />

          <motion.div variants={itemVariants} className="col-span-12 p-2 bg-[#fc8972] rounded-2xl">
            <div className="flex flex-col justify-center items-center">
              <img
                src={consultingImg}
                alt="Free Consultation"
                className="h-[250px] transition-transform duration-300 ease-in-out"
              />
              <div className="flex flex-col justify-center gap-1 text-center">
                <h1 className="text-xl text-[#862b1b] font-semibold">Free Consultation</h1>
                <p className="text-[#862b1b] text-md">
                  Speak with our experts to understand how a tailored eCommerce website can help your business thrive.
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-12 bg-[#848ef9] text-[#1a1f5b] rounded-2xl flex flex-col">
            <div>
              <img src={plansImg} alt="Personalized Project Plan" className="h-[300px] w-full transform scale-x-[-1]" />
            </div>
            <div className="flex flex-col justify-center gap-2 p-4  text-center">
              <h1 className="text-xl font-semibold ">Personalized Project Plan</h1>
              <p className="text-md">Receive a detailed project plan with timelines, costs, and milestones to ensure timely delivery.</p>
            </div>

          </motion.div>

          <motion.div variants={itemVariants} className="col-span-12 p-4 bg-[#F0F5FB] text-[#2e598e] rounded-2xl  text-center">
            <h1 className="text-xl  font-semibold ">Integration with Payment Gateways</h1>
            <img src={paymentImg} alt="Payment Integration" />
            <p className=" text-md">Secure payment gateways and tools for confident shopping and efficient management.</p>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-12 bg-[#ffba08] text-[#681c3b] p-4 rounded-2xl flex flex-col items-center justify-center  text-center">
            <h1 className="text-xl font-semibold ">SEO & Performance Optimization</h1>
            <img src={seoImg} alt="SEO & Performance Optimization" className="h-[200px]" />
            <p className="text-md">Best practices for SEO and speed to ensure high rankings and fast loading times.</p>
          </motion.div>


          <motion.div variants={itemVariants} className="col-span-12 bg-[#ABADDD] p-4 rounded-2xl flex flex-col justify-center text-center">
            <h1 className="text-xl font-semibold text-[#363853]">Fully Responsive & Scalable</h1>
            <img src={responsiveImg} alt="Fully Responsive" className="" />
            <p className="text-md text-[#363853]">Optimized for any device to ensure a seamless shopping experience across all platforms.</p>
          </motion.div>

          <motion.div variants={itemVariants} className="col-span-12 bg-[#4BAB8A] p-4 rounded-2xl flex flex-col justify-center">
            <img src={supportImg} alt="Ongoing Support" className="h-[242px] w-full" />

            <div className="flex flex-col gap-2 justify-center  text-center">
              <h1 className="text-xl text-[#18382c] font-semibold ">Ongoing Support</h1>
              <p className="text-[#18382c] text-md">We provide ongoing support and maintenance to keep your store running smoothly and securely.</p>
            </div>

          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Alternative;

const HeaderBlock = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: true, threshold: 0.3 });

  return (
    <motion.div
      ref={ref}
      initial={{ scale: 0.5, y: 50, opacity: 0 }}
      animate={inView ? { scale: 1, y: 0, opacity: 1 } : undefined}
      transition={{
        type: "spring",
        mass: 3,
        stiffness: 400,
        damping: 50,
        delay: 0.2,
      }}
      className="col-span-12 md:col-span-4"
    >
      <h1 className="text-3xl md:text-4xl text-center text-black font-bold mt-2 drop-shadow-lg">
        Why <span className="text-[#F5A841]">Choose</span> us?
      </h1>
    </motion.div>
  );
};

