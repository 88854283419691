import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import abtImg from '../Assets/about us (Website).png';
import { motion, useAnimation } from 'framer-motion';
import CountUp from 'react-countup';

const About = () => {
  const controls = useAnimation();
  const [textRef, textInView] = useInView({
    triggerOnce: true,
    threshold: 0.2, 
  });
  const [imageRef, imageInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [statsRef, statsInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (textInView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, ease: 'easeInOut' },
      });
    }
  }, [controls, textInView]);

  useEffect(() => {
    if (imageInView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: 'easeInOut' },
      });
    }
  }, [controls, imageInView]);

  useEffect(() => {
    if (statsInView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: 'easeInOut' },
      });
    }
  }, [controls, statsInView]);

  const stats = [
    { count: 10, label: 'Trusted Customers' },
    { count: 100, label: 'Product Delivery Rate', suffix: '%' },
    { count: 7, label: 'Countries' },
    { count: 100, label: 'Business Trust', suffix: '%' },
  ];

  return (
    <section className="h-screen mx-auto max-w-[1100px] py-12 about -mt-10 md:-mt-0">
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2"
        ref={textRef}
        initial={{ opacity: 0, y: 100 }}
        animate={controls}
      >
        <div className="flex flex-col gap-6 justify-center items-center md:items-start p-6 md:p-0 -mt-20 md:-mt-0">
          <motion.h1 className="text-3xl md:text-4xl font-bold drop-shadow-lg">
            About Us
          </motion.h1>
          <motion.div className="text-md md:text-lg text-center md:text-left text-neutral-700 flex flex-col w-full md:w-[600px]">
            <p className=''>
              NAMUVI Technologies was founded in 2023 with a clear vision and passion for innovation.
              Located in the bustling city of Chennai, our company was established by a dynamic
              team of visionary entrepreneurs who foresaw the transformative power of technology
              in reshaping industries and fostering meaningful connections.
            </p>
            <p className="mt-6">
              At NAMUVI, we are dedicated to harnessing cutting-edge technology to develop impactful solutions
              that empower businesses and individuals to thrive in the digital age.
            </p>
          </motion.div>
        </div>
        <motion.img
          src={abtImg}
          alt="About Us"
          className="h-[500px] ml-32 -mt-5 hidden md:flex"
          ref={imageRef}
          initial={{ opacity: 0, y: 100 }}
          animate={controls}
        />
      </motion.div>

      <div className='p-4 md:p-0'>
      <motion.div
        className="z-20 max-w-5xl rounded-lg bg-[#F5A841] shadow-2xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center justify-between p-3 md:p-5 gap-x-2 md:gap-x-8 px-5 md:px-10 lg:px-10 gap-y-4 md:gap-y-5"
        ref={statsRef}
        initial={{ opacity: 0, y: 100 }}
        animate={controls}
        transition={{ delay: 1, duration: 0.8, ease: 'easeInOut' }}
      >
        {stats.map((stat, index) => (
          <div key={index} className="col-span-1 flex flex-col items-center justify-center gap-y-3">
            <h2 className="text-3xl md:text-5xl text-white font-bold">
              <CountUp start={0} end={stat.count} duration={3} suffix={stat.suffix || ''} />
            </h2>
            <p className="text-center text-sm md:text-base text-white font-bold">{stat.label}</p>
          </div>
        ))}
      </motion.div>
      </div>
    </section>
  );
};

export default About;
