import React, { useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import contactImg from '../Assets/contact.png';
import mainLogo from '../Assets/icons/Namuvi-logo-light.png';
import { IoLocationSharp, IoMail } from "react-icons/io5";
import FbIcon from '../Assets/icons/facebook.png';
import InstaIcon from '../Assets/icons/instagram.png';
import LinkedinIcon from '../Assets/icons/linkedin.png';
import ContactModal from './ContactModal';

const Footer = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <div className='relative'>
             <img src={contactImg} alt="" className='-mt-[240px]  h-[320px] absolute hidden md:flex'/>
            <section className='bg-gray-900 pt-12 rounded-t-3xl md:rounded-t-[60px]'>
            <div className='text-white max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center justify-center'>
                <h2 className="text-3xl md:text-4xl mb-6 font-semibold leading-relaxed text-center md:text-left">
                    Ready to build <span className='text-[#F5A841]'>your empire?</span> <br />Reach out to us!
                </h2>
                <button
                    onClick={() => setModalOpen(true)}
                    className="text-black flex gap-2 mx-auto shadow-xl text-lg bg-gray-50 lg:font-semibold before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-[#F5A841] hover:text-gray-50 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-4 py-2 overflow-hidden rounded-full group"
                >
                    Contact Us
                    <svg
                        className="w-8 h-8 justify-end group-hover:rotate-90 bg-[#F5A841] group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full group-hover:border-none p-2 rotate-45"
                        viewBox="0 0 16 19"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"
                            className="fill-gray-800 group-hover:fill-gray-800"
                        ></path>
                    </svg>
                </button>
            </div>
            <footer className='max-w-[1240px] mx-auto text-white '>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 py-6'>
                    <div className='col-span-2 text-center md:text-left'>
                        <img src={mainLogo} alt="Namuvi Logo" className='h-12 mx-auto md:mx-0' />
                        <div className='flex flex-col gap-2 mt-4'>
                            <p className='text-md'>Let's build your <span className='text-[#F5A841]'>digital empire</span> together</p>
                            <div className='mt-2'>
                                <ul className='flex justify-center md:justify-start gap-4'>
                                    <li>
                                        <a href="https://www.linkedin.com/company/namuvi-technologies-private-limited/" target='_blank' rel="noopener noreferrer">
                                            <img src={LinkedinIcon} alt="LinkedIn" className='h-7 w-7' />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/people/Namuvi/61558573185986/" target='_blank' rel="noopener noreferrer">
                                            <img src={FbIcon} alt="Facebook" className='h-7 w-7 bg-white rounded-lg' />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/namuvi_tech/" target='_blank' rel="noopener noreferrer">
                                            <img src={InstaIcon} alt="Instagram" className='h-8 w-8' />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <nav className='text-sm '>
                            <ul className='cursor-pointer py-4 space-x-5 md:space-x-0  md:space-y-2 flex md:flex-col justify-center'>
                                <li className='hover:text-[#F5A841]'>Home</li>
                                <li className='hover:text-[#F5A841]'>About Us</li>
                                <li className='hover:text-[#F5A841]'>Services</li>
                                <li className='hover:text-[#F5A841]'>Projects</li>
                            </ul>
                        </nav>
                    </div>

                    <div className='flex flex-col gap-4 justify-center items-center md:items-start text-sm'>
                        <div className='flex gap-2 items-center'>
                            <IoLocationSharp className='w-4 h-6' />
                            <p className='text-center md:text-left'>NAMUVI Technologies Pvt. Ltd,<br />Ambattur, Chennai - 600 066</p>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <FaPhoneAlt className='w-4 h-6' />
                            <p className='text-center md:text-left'>+91 99627 44380</p>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <IoMail className='w-4 h-6' />
                            <p className='text-center md:text-left'>business@namuvi.com</p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='p-2'>
                <p className='text-center text-gray-600 text-sm'>© 2024 Namuvi Technologies. All rights reserved.</p>
            </div>
            <ContactModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
            />
        </section>
        </div>
    );
}

export default Footer;
