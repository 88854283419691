import { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; 
import Logo from '../Assets/icons/Namuvi-logo-light.png';
import { Link } from 'react-scroll'; 

export default function Header ({ openContactModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <header
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 w-[95%] md:w-full max-w-[1340px] z-50 rounded-2xl p-3 px-4 bg-gray-800 text-white transition-all duration-500 shadow-lg ease-in-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
        }`}
    >
      <div className='flex justify-between items-center'>
        <img src={Logo} alt="logo" className='h-9' />
        <nav className='hidden md:flex'>
          <ul className='flex space-x-8 font-semibold cursor-pointer'>
            <li className='hover:text-[#F5A841]'>
              <Link to="home" smooth={true} duration={500} offset={-70}>
                Home
              </Link>
            </li>
            <li className='hover:text-[#F5A841]'>
              <Link to="about" smooth={true} duration={500} offset={-70}>
                About Us
              </Link>
            </li>
            <li className='hover:text-[#F5A841]'>
              <Link to="services" smooth={true} duration={500} offset={-70}>
                Services
              </Link>
            </li>
            <li className='hover:text-[#F5A841]'>
              <Link to="products" smooth={true} duration={500} offset={-70}>
                Products
              </Link>
            </li>
          </ul>
        </nav>

        <button
          className='hidden md:block bg-[#F5A841] text-black font-semibold px-4 py-2 rounded-xl cursor-pointer hover:text-gray-100'
          onClick={openContactModal}
          >
          Contact
        </button>

        <div className='md:hidden'>
          <FaBars className='text-xl cursor-pointer' onClick={toggleMenu} />
        </div>
      </div>

      {isOpen && (
        <div className='absolute top-0 left-0 w-full h-screen bg-gray-900 z-50'>
          <div className="flex justify-end p-4">
            <FaTimes className="text-2xl text-white cursor-pointer" onClick={toggleMenu} /> {/* Close icon */}
          </div>
          <ul className='flex flex-col space-y-6 items-center font-semibold text-white cursor-pointer'>
            <li>
              <Link
                to="home"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={toggleMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={toggleMenu}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="services"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={toggleMenu}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="products"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={toggleMenu}
              >
                Products
              </Link>
            </li>
            <li>
              <button
                className='bg-[#F5A841] text-black font-semibold px-4 py-2 rounded-xl'
                onClick={openContactModal}
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}
