import React from 'react';

const BackgroundSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnsSvgjs="http://svgjs.dev/svgjs"
    viewBox="0 0 800 450"
    className="absolute inset-0 w-full h-full"
    preserveAspectRatio="none"
  >
    <defs>
      <filter
        id="bbblurry-filter"
        x="-100%"
        y="-100%"
        width="400%"
        height="400%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur
          stdDeviation="100"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          in="SourceGraphic"
          edgeMode="none"
          result="blur"
        ></feGaussianBlur>
      </filter>
    </defs>
    <g filter="url(#bbblurry-filter)">
      <ellipse
        rx="300"
        ry="50"
        cx="200"  
        cy="0"
        fill="#fca311"  
      ></ellipse>
      {/* Blue blur at the bottom-left corner */}
      <ellipse
        rx="100"
        ry="40"
        cx="800"
        cy="250"  
        fill="#fca311" 
      ></ellipse>
      {/* <ellipse
        rx="150"
        ry="150"
        cx="0"
        cy="500"  
        fill="#fca311" 
      ></ellipse> */}
    </g>
  </svg>
);

export default BackgroundSvg;

