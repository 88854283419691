import React, { useEffect, useState, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import eventBeeImg from '../Assets/projects/eventbee.png';
import cosmicImg from '../Assets/projects/cosmic.png';
import oneEventImg from '../Assets/projects/one-events.png';
import sellero2Img from '../Assets/projects/sellero2.png';
import torqueImg from '../Assets/projects/torque.png';
import herbozoneImg from '../Assets/projects/herbozone.png';

const projects = [
    { id: 1, imgUrl: sellero2Img, title: 'Sellero2', bg: '#ffb703', url: 'https://sellero2.com' },
    { id: 2, imgUrl: oneEventImg, title: 'One Company', bg: '#219ebc', url: 'https://theonecompany.in' },
    { id: 3, imgUrl: torqueImg, title: 'Torque', bg: '#60d394', url: 'https://torqueentertainment.com' },
    { id: 4, imgUrl: eventBeeImg, title: 'Event Bee', bg: '#fdc5f5', url: 'https://eventbee.in/' },
    { id: 5, imgUrl: cosmicImg, title: 'Cosmic', bg: '#7fc8f8', url: 'https://cosmicpvtltd.com' },
    { id: 6, imgUrl: herbozoneImg, title: 'HerbOzone', bg: '#f1f2f3', url: 'https://herbozone.in/' }
];

const ParallaxGallery = () => {
    const galleryRef = useRef(null);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const [cursorData, setCursorData] = useState({ visible: false, title: '', bg: '' });
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

    const { scrollYProgress } = useScroll({
        target: galleryRef,
        offset: ['start end', 'end start']
    });

    const y1 = useTransform(scrollYProgress, [0, 1], [0, dimension.height * 1.5]);
    const y2 = useTransform(scrollYProgress, [0, 1], [0, dimension.height * 1.8]);

    useEffect(() => {
        const resize = () => {
            setDimension({ width: window.innerWidth, height: window.innerHeight });
        };

        const handleMouseMove = (e) => {
            setMousePos({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('resize', resize);
        window.addEventListener('mousemove', handleMouseMove);

        resize();

        return () => {
            window.removeEventListener('resize', resize);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const convertHexToRgba = (hex, alpha = 1) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const cursorStyle = {
        top: `${mousePos.y}px`,
        left: `${mousePos.x}px`,
        backgroundColor: cursorData.bg ? convertHexToRgba(cursorData.bg, 0.8) : 'rgba(0, 0, 0, 0.9)',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        zIndex: 1000,
    };

    return (
        <main className="relative w-full products">
            {cursorData.visible && (
                <div
                    className='fixed bg-black p-4 w-32 h-32 rounded-full flex items-center justify-center pointer-events-none'
                    style={cursorStyle}
                >
                    <p className='text-sm font-semibold text-center'>{cursorData.title}</p>
                </div>
            )}

            <div className='max-w-[1240px] mx-auto'>
                <h1 className='text-3xl md:text-4xl font-bold text-center drop-shadow-lg'>
                    Take a look at some of <span className='text-[#F5A841]'>Our projects</span>
                </h1>
            </div>

            <div ref={galleryRef} className="hidden md:block gallery-container max-w-[1140px] mx-auto md:h-[332vh] overflow-hidden relative">
                <div className="gallery-wrapper relative flex flex-col md:flex-row gap-2 md:gap-10 p-8" style={{ top: '-19.5vh', height: '200vh' }}>
                    <Column images={[projects[0], projects[1], projects[2]]} y={y1} setCursorData={setCursorData} />
                    <Column images={[projects[3], projects[4], projects[5]]} y={y2} setCursorData={setCursorData} />
                </div>
            </div>
            {/* Mobile view */}
            <div className="block md:hidden">
                <div className="columns-2 gap-4 p-4 space-y-4">
                    {projects.map((project) => (
                        <div
                            key={project.id}
                            className="relative overflow-hidden rounded-2xl shadow-lg break-inside-avoid group"
                        >
                            <a
                                key={project.id}
                                href={project.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                <img
                                    src={project.imgUrl}
                                    alt={project.title}
                                    className="w-full object-cover transform transition-transform duration-300 hover:scale-105 h-[200px]"
                                />
                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white p-2 text-sm text-center opacity-0 transform translate-y-full transition duration-300 group-hover:opacity-100 group-hover:translate-y-0">
                                    {project.title}
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>

        </main>
    );
};

const Column = ({ images, y, setCursorData }) => {
    return (
        <motion.div className="flex flex-col justify-center items-center  gap-2 md:gap-10 relative" style={{ y }}>
            {images.map((project, i) => (
                <a href={project.url} key={i} target="_blank" rel="noopener noreferrer" className="relative w-full md:w-[500px] rounded-[40px] overflow-hidden cursor-pointer">
                    <img
                        src={project.imgUrl}
                        alt={`project-${i}`}
                        className="w-full h-[300px] md:h-full object-cover transform transition-transform duration-300 hover:scale-105"
                        onMouseEnter={() => setCursorData({ visible: true, title: project.title, bg: project.bg })}
                        onMouseLeave={() => setCursorData({ visible: false, title: '', bg: '' })}
                    />
                </a>
            ))}
        </motion.div>
    );
};

export default ParallaxGallery;
