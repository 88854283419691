import React, { useState } from 'react';
import successImg from '../Assets/Successfully Done.gif';

const ContactModal = ({ isOpen, onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      Name: e.target.name.value,
      Email: e.target.email.value,
      Phone: e.target.phone.value,
      ProjectType: e.target.projectType.value,
      Message: e.target.message.value
    };
  
    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbyXoTjfCm8ZbkPvvpBg9nNQ2K76acUihX8D_XEbLOiGokIC0Sk0sF3SRzNRttP-V40sOg/exec", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(formData)
      });
      
      const result = await response.text();
      console.log(result);
      
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClose = () => {
    setIsSubmitted(false); 
    onClose();
  };

  return (
    <div className="modal-overlay " onClick={handleClose} aria-modal="true" role="dialog">
      <div
        className="modal-content bg-gray-800 rounded-2xl"
        onClick={(e) => e.stopPropagation()}
        role="document"
        aria-labelledby="modalTitle"
        tabIndex={-1}
      >
        <button
          className="close-button text-xl"
          onClick={handleClose}
          aria-label="Close modal"
        >
          &times;
        </button>

        {isSubmitted ? (
          <div className="thank-you-message text-center text-white">
            <img src={successImg} alt="Thank You" className="mx-auto" />
            <h2 id="modalTitle" className="text-2xl font-semibold mb-2">
              Thank You!
            </h2>
            <p className="font-normal mb-4">
              Our team will contact you within 24 hours.
            </p>
            <button
              className="mt-4 bg-orange-400 text-white py-2 px-4 rounded-lg"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className='text-white'>
            <h2 id="modalTitle" className="text-2xl text-center font-bold mb-4">
              Contact Us
            </h2>
            <label className="block mb-2 font-semibold">
              Name
              <input
                type="text"
                name="name"
                className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg"
                required
              />
            </label>

            <label className="block font-semibold mb-2">
              Email
              <input
                type="email"
                name="email"
                className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg"
                required
              />
            </label>

            <label className="block font-semibold mb-2">
              Phone
              <input
                type="tel"
                name="phone"
                className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg"
                required
              />
            </label>

            <label className="block font-semibold mb-2">
              What do you need to build?
              <select
                name="projectType"
                className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg"
                required
              >
                <option value="" disabled selected>
                  Select a project type
                </option>
                <option value="webdev">Web Development</option>
                <option value="ecomm">eCommerce Application</option>
                <option value="saas">SaaS Application</option>
                <option value="uiux">UI/UX Design</option>
                <option value="appdev">App Development</option>
              </select>
            </label>

            <label className="block font-semibold mb-4">
              Message
              <textarea
                name="message"
                className="font-normal w-full mt-1 px-4 py-2 text-black  rounded-lg"
                rows="2"
                required
              ></textarea>
            </label>

            <button
              type="submit"
              className="w-full bg-orange-400 font-bold text-black py-2 rounded-lg"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactModal;
