import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ReactTyped } from 'react-typed';
import BackgroundSvg from './BackgroundSvg';
import About from './About'

const Home = ({ openContactModal }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section className='relative'>
      <BackgroundSvg />
      <main className="home relative flex overflow-hidden">
      <div className="h-screen w-full">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_20px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]"></div>
        <section className="max-w-[1240px] mx-auto mt-36 md:mt-40 z-20">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
            className="flex flex-col gap-6 items-center font-bold text-4xl text-center md:text-8xl drop-shadow-md"
          >
            <motion.p
              initial={{ opacity: 0, x: -50 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5 }}
              className=''
            >
              Let's build your
            </motion.p>

            <motion.p
              initial={{ opacity: 0, x: 50 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              <span className="">digital{" "}</span>

              <ReactTyped
                strings={['Empire', 'Brand', 'Platform']}
                typeSpeed={70}
                backSpeed={70}
                loop
                showCursor={false}
                className="text-[#F5A841] drop-shadow-md"
              />
            </motion.p>

            {/* <div className="relative w-full h-12 block md:hidden">
                <ReactTyped
                  strings={['Empire', 'Brand', 'Platform']}
                  typeSpeed={70}
                  backSpeed={70}
                  loop
                  showCursor={false}
                  className="text-[#F5A841] drop-shadow-md absolute top-0 left-0 w-full"
                />
              </div> */}

            <motion.p
              initial={{ opacity: 0, x: -50 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              together
            </motion.p>

            <button
              className="button"
              onClick={openContactModal}
            >
              Let's Build!!
            </button>
          </motion.div>
        </section>
      </div>
    </main>
    <About/>
    </section>
  );
};

export default Home;