import React, { useState } from 'react';
import Header from "./Components/Header";
import Home from './Components/Home';
import WhatWeDo from './Components/Whatwedo/WhatWeDo';
import HowWeWork from './Components/HowWeWork';
import Footer from './Components/Footer';
import WhatsAppButton from './Components/WhatsAppBtn';
import Alternative from './Components/Alternative';
import OurWorks from './Components/OurWorks';
import ContactModal from './Components/ContactModal';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="App">
      <Header openContactModal={openModal} />
      <Home openContactModal={openModal} />
      <Alternative />
      <WhatWeDo openContactModal={openModal}/>
      <OurWorks />
      {/* <HowWeWork /> */}
      <Footer />
      <WhatsAppButton />
      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default App;
